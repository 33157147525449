import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import Filters from './components/Filters';
import ListHeader from './components/header';
import List from './components/list';
import SearchAtPage from './components/searchAtPage';
import SubscriberDetailsModal from './components/SubscriberDetailsModal';
import useSubscribers from './useSubscribers';

export default function Subscribers() {
  const {
    isLoading,
    fullSubscribers,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    filteredSubscribers,
    handleTryAgain,
    pagesQuantity,
    currentPage,
    companysList,
    selectedCompany,
    handleCompanyChange,
    cuponsList,
    selectedCupom,
    handleCupomChange,
    statusOptions,
    selectedStatus,
    handleStatusChange,
    selectedKind,
    handleKindChange,
    handlePageChange,
    handleOpenDetailModal,
    detailModalShow,
    subscriberDetailsBeingShow,
    detailView,
    setDetailView,
    handleCloseDetailModal,
    isSubscriberDetailsLoading,
    downloadExcel,
    selectedEngagement,
    engagementOptions,
    handleEngagementChange,
    handleAgeSelection,
    selectedAgeRange,
    setSelectedAgeRange,
  } = useSubscribers();

  const hasSubscribers = fullSubscribers?.length !== 0 && !!fullSubscribers && !isLoading;
  const filteredListLength = filteredSubscribers?.length ?? 0;
  const anyResultsByFilterSelection = !filteredSubscribers && !isLoading;
  const isListEmpty = !doesListApiHasError && (!isLoading && fullSubscribers.length === 0);

  return (
    <>
      <Sidebar active="Subscribers" />
      <Transitions>
        <Loader isLoading={isLoading || isSubscriberDetailsLoading} />

        {hasSubscribers && (
          <>
            <Filters
              companysList={companysList}
              selectedCompany={selectedCompany}
              cuponsList={cuponsList}
              selectedCupom={selectedCupom}
              statusOptions={statusOptions}
              selectedKind={selectedKind}
              selectedStatus={selectedStatus}
              handleStatusChange={handleStatusChange}
              handleKindChange={handleKindChange}
              handleCompanyChange={handleCompanyChange}
              handleCupomChange={handleCupomChange}
              selectedEngagement={selectedEngagement}
              engagementOptions={engagementOptions}
              handleEngagementChange={handleEngagementChange}
              selectedAgeRange={selectedAgeRange}
              handleAgeSelection={handleAgeSelection}
              setSelectedAgeRange={setSelectedAgeRange}
            />
            <SearchAtPage
              searchTerm={searchTerm}
              onChangeSearchTerm={handleChangeSearchTerm}
            />
          </>
        )}

        <ListHeader
          doesListExists={hasSubscribers}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel="assinante"
          pluralLabel="assinantes"
          onPageChange={handlePageChange}
          pagesQuantity={pagesQuantity}
          currentPage={currentPage}
          downloadExcel={downloadExcel}
        />

        {hasSubscribers && (
          <List
            filteredList={filteredSubscribers}
            handleOpenDetailModal={handleOpenDetailModal}
          />
        )}

        <SubscriberDetailsModal
          detailModalShow={detailModalShow}
          handleCloseDetailModal={handleCloseDetailModal}
          subscriberDetailsBeingShow={subscriberDetailsBeingShow}
          setDetailView={setDetailView}
          detailView={detailView}
        />

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter os assinantes.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
)}
          />
        )}

        {anyResultsByFilterSelection && (
        <NoData
          icon="searchNotFound"
          label={(
            <>
              Não encontramos nenhum resultado conforme os filtros selecionados.
              Por favor, tente alterar os critérios.
            </>
)}
        />
        )}

        {isListEmpty && !isLoading && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhum assinante cadastrada
              </>
)}
          />
        )}
      </Transitions>
    </>
  );
}
