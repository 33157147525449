import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../hooks/useApiCall';
import companiesService from '../../../../../services/companiesService';
import splitArray from '../../../../../utils/splitArray';
import { UsersList } from '../../interfaces/UsersList';

type SplitedUsersArray = Array<UsersList[]> | [[]];

interface UseLoadUsersInterface {
  setUsersSplited: Dispatch<SetStateAction<Array<UsersList[]>> | [[]]>;
  setFilteredUsers: Dispatch<SetStateAction<UsersList[] | []>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

type UsersFromApiResponse = {
  auth_id: string;
  object_id: string;
  id: string;
  associated_company?: string | null;
  empresa_suspenso?: string | null;
  data_ativacao?: string | null;
  email?: string | null;
  celular?: string | null;
  nome?: string | null;
}

export default function useLoadUsers({
  setUsersSplited,
  setFilteredUsers,
  setCurrentPage,
}: UseLoadUsersInterface) {
  const [fullUsers, setFullUsers] = useState<UsersList[]>([]);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [associatedCompanies, setAssociatedCompanies] = useState<string[]>([]);
  const [lastUpdate, setLastUpdate] = useState<Date | string>('');
  const [authenticationType, setAuthenticationType] = useState<string>('');

  const { apiCall } = useApiCall();
  const navigate = useNavigate();

  const { companyId } = useParams();

  const loadUsers = useCallback(async () => {
    if (!companyId) {
      toast.error('Não identificamos a empresa através da URL. Por favor, tente novamente.');
      navigate('/companies');
      return;
    }

    await apiCall({
      apiToCall: companiesService.listUsers,
      queryParams: { companyId },
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error && apiResponse.error !== 'sem usuarios') {
          toast.error(`Não foi possível carregar a lista dos colaboradores (${apiResponse.error})`, {
            toastId: 'loadUsersFail',
          });
          setDoesListApiHasError(true);
          setIsLoadLoading(false);
          return;
        }
        const associatedCompaniesFromApi: { name: string, createdAt: Date }[] = apiResponse.associatedCompanies;
        const usersList: UsersFromApiResponse[] = apiResponse?.result;
        const mappedUsersList = usersList?.map((collaborator) => ({
          _id: collaborator.object_id ?? collaborator.auth_id ?? collaborator.id,
          id: collaborator.id,
          dataAtivacao: collaborator.data_ativacao || 'Nunca ativado',
          email: collaborator.email,
          cellphone: collaborator.celular,
          name: collaborator.nome,
          // eslint-disable-next-line no-nested-ternary
          status: (collaborator.empresa_suspenso
            ? 'Suspenso'
            : (
              collaborator.data_ativacao
                ? 'Ativo'
                : 'Registrado'
            )),
          // eslint-disable-next-line no-nested-ternary
          codStatus: (collaborator.empresa_suspenso
            ? 2
            : (
              collaborator.data_ativacao
                ? 1
                : 3
            )),
          associatedCompany: collaborator.associated_company,
        }));
        setLastUpdate(apiResponse.lastUpdate);
        setFullUsers(mappedUsersList);
        setAssociatedCompanies(associatedCompaniesFromApi.map((x) => x.name))
        setAuthenticationType(apiResponse.authenticationType);

        const splitedArray: SplitedUsersArray = splitArray(mappedUsersList);
        setUsersSplited(splitedArray);
        setFilteredUsers(splitedArray[0]);
        setCurrentPage(0);
        setDoesListApiHasError(false);
      },
      catchMessage: 'Não foi possível carregar a lista dos colaboradores',
      catchAction: () => setDoesListApiHasError(true)
    });
  }, [apiCall, companyId, navigate, setCurrentPage, setFilteredUsers, setUsersSplited]);

  return {
    fullUsers,
    doesListApiHasError,
    isLoadLoading,
    loadUsers,
    associatedCompanies,
    lastUpdate,
    authenticationType,
  };
}
