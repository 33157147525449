import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../hooks/useApiCall';
import usersService from '../../services/usersService';
import removeDuplicates from '../../utils/removeDuplicates';

type SelectedTargetType = {
  label: string;
  value: string;
};

type SubscriberFromApi = {
  assinatura_status: string;
  celular: string;
  data_ativacao: string;
  email: string;
  empresa: string;
  nome: string;
  _id: string;
  company?: {
    id?: string;
    nome?: string;
  };
  cupom_utilizado?: {
    code?: string;
  }
};

type UserFromApi = {
  email: string;
  nome: string;
  assinatura_status: 'Ativa' | 'Cancelada';
  data_ativacao?: string;
}

export default function useSendEmail() {
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [selectedEmailTarget, setSelectedEmailTarget] = useState<SelectedTargetType>({} as SelectedTargetType);
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [image, setImage] = useState<File | string | undefined>('');
  const [imageFileName, setImageFileName] = useState('');
  const [pdf, setPdf] = useState<File | string | undefined>('');
  const [pdfFileName, setPdfFileName] = useState('');
  const [companyTarget, setCompanyTarget] = useState('subscribers');

  const [willSendEmail, setWillSendEmail] = useState(false);
  const canSubmitEmail = useMemo(() => (
    !!(!!subject && !!selectedEmailTarget.value && image && pdf)
  ), [subject, selectedEmailTarget.value, image, pdf]);

  const [targetEmailOptions, setTargetEmailOptions] = useState([
    { value: 'Teste', label: 'Teste' },
    { value: 'Assinantes', label: 'Assinantes' },
    { value: 'Cadastrados', label: 'Cadastrados' },
    { value: 'Todos', label: 'Todos' },
  ]);

  const { apiCall } = useApiCall();

  function handleTextChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setText(event.target.value);
  }

  function handleSubjectChange(event: ChangeEvent<HTMLInputElement>) {
    setSubject(event.target.value);
  }

  function handleImageUpload(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (!files) {
      toast.error('Não foi possível subir esse arquivo. Por favor, tente novamente')
      return;
    }
    setImage(files[0])
    setImageFileName(files[0].name)
  }

  function removeImage() {
    setImageFileName('');
    setImage(undefined);
  }

  function handlePdfUpload(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (!files) {
      toast.error('Não foi possível subir esse arquivo. Por favor, tente novamente')
      return;
    }
    setPdf(files[0])
    setPdfFileName(files[0].name)
  }

  function removePdf() {
    setPdfFileName('');
    setPdf(undefined);
  }

  const loadCompaniesAndUsers = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.getUsersList,
      onStartLoad: () => setIsEmailLoading(true),
      onEndLoad: () => setIsEmailLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar todas as opções de envio de notificações push (${apiResponse.error})`, {
            toastId: 'getSubscribersFail',
          });
          setIsEmailLoading(false);
          return;
        }
        const subscribersList: SubscriberFromApi[] = apiResponse.list;
        const companiesFromSubscribers = subscribersList?.map((subscriber) => (
          {value: subscriber.company?.id || null, label: subscriber.company?.nome || 'B2C'}
        ));

        const companies = removeDuplicates(companiesFromSubscribers);
        const companiesFiltered = companies.filter((company) => company.label !== 'JaSouKiddle');

        const usersList: UserFromApi[] = apiResponse.list;

        const mappedUsersToTargetOptions = usersList.map((usr) => ({
          value: usr.email, label: usr.nome
        }))

        setTargetEmailOptions((prevState: { value: string, label: string }[]) => (
          removeDuplicates([...prevState, ...companiesFiltered, ...mappedUsersToTargetOptions])
        ));
      },
      catchMessage: 'Não foi possível carregar as empresas para envio de e-mails'
    })
  }, [apiCall, setTargetEmailOptions]);

  const sendEmail = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.sendEmail,
      reqBody: [
        { key: 'subject', value: subject },
        { key: 'text', value: text },
        { key: 'target', value: selectedEmailTarget.value },
        { key: 'companyTarget', value: companyTarget },
        { key: 'pdf', value: pdf },
        { key: 'image', value: image },
      ],
      onStartLoad: () => setIsEmailLoading(true),
      onEndLoad: () => {
        setIsEmailLoading(false);
        setWillSendEmail(false);
      },
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível enviar os e-mails. Por favor, tente novamente');
          setWillSendEmail(false);
          return;
        }
        toast.success('O email foi enviado com sucesso');
        setWillSendEmail(false);
      },
      catchMessage: 'Não foi possível enviar o e-mail'
    })
  }, [apiCall, companyTarget, image, pdf, selectedEmailTarget.value, subject, text]);

  useEffect(() => {
    if (targetEmailOptions.length <= 4 && emailModalShow) {
      loadCompaniesAndUsers();
    }
  }, [loadCompaniesAndUsers, emailModalShow, targetEmailOptions.length])

  return {
    emailModalShow,
    setEmailModalShow,
    sendEmail,
    selectedEmailTarget,
    targetEmailOptions,
    setSelectedEmailTarget,
    handleTextChange,
    text,
    isEmailLoading,
    setWillSendEmail,
    willSendEmail,
    canSubmitEmail,
    subject,
    image,
    imageFileName,
    pdf,
    pdfFileName,
    companyTarget,
    setCompanyTarget,
    handleImageUpload,
    removeImage,
    handlePdfUpload,
    removePdf,
    handleSubjectChange,
  };
}
