/* eslint-disable no-nested-ternary */
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Select from 'react-select';

import { CustomStyle } from '../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../components/CustomSelectStyleDarkTheme';
import FilterRadioButton from '../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../components/FilterRadioButtonsContainer';
import useThemeContext from '../../../../contexts/theme';
import { Container, Group } from './styles';

import filter from '../../../../assets/images/icons/filter.svg';
import { Dispatch, SetStateAction } from 'react';

interface FiltersInterface {
  companysList: { value: string, label: string }[];
  selectedCompany: { value: string, label: string };
  cuponsList: { value: string, label: string }[];
  selectedCupom: { value: string, label: string };
  statusOptions: { value: string, label: string }[];
  engagementOptions: { value: string, label: string }[];
  selectedKind: string;
  selectedStatus: { value: string, label: string };
  selectedEngagement: { value: string, label: string };
  handleStatusChange: (status: { value: string, label: string }) => void;
  handleKindChange: (kind: string) => void ;
  handleCompanyChange: (company: { value: string, label: string }) => void;
  handleCupomChange: (cupom: { value: string, label: string }) => void;
  handleEngagementChange: (cupom: { value: string, label: string }) => void;
  selectedAgeRange: number[];
  setSelectedAgeRange: Dispatch<SetStateAction<number[]>>;
  handleAgeSelection: (ageRange: number[]) => void;
}

export default function Filters({
  companysList,
  selectedCompany,
  cuponsList,
  selectedCupom,
  statusOptions,
  selectedKind,
  selectedStatus,
  handleStatusChange,
  handleKindChange,
  handleCompanyChange,
  handleCupomChange,
  selectedEngagement,
  engagementOptions,
  handleEngagementChange,
  selectedAgeRange,
  handleAgeSelection,
  setSelectedAgeRange,
}: FiltersInterface) {
  const { selectedTheme } = useThemeContext();

  return (
    <Container>
      <header>
        <img src={filter} alt="filter" className="primaryColor" />
        <div>Pesquise através dos filtros abaixo</div>
      </header>

      <div>
        <Select
          value={{ value: selectedCompany.value, label: selectedCompany.label === 'Todas' ? 'Filtrar por empresa' : selectedCompany.label }}
          options={companysList}
          onChange={(company) => handleCompanyChange(company as { value: string, label: string })}
          placeholder="Filtrar por empresa"
          styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
          // menuPortalTarget={document.body}
          classNamePrefix="react-select"
          className="react-select-container-as-filter"
        />
        <Select
          value={{ value: selectedCupom.value, label: selectedCupom.label === 'Todos' ? 'Filtrar por cupom' : selectedCupom.label }}
          options={cuponsList}
          onChange={(cupom) => handleCupomChange(cupom as { value: string, label: string })}
          placeholder="Filtrar por cupom"
          styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
          // menuPortalTarget={document.body}
          classNamePrefix="react-select"
          className="react-select-container-as-filter"
        />
        <Select
          value={{ value: selectedStatus.value, label: selectedStatus.label === 'Todos' ? 'Filtrar por status' : selectedStatus.label }}
          options={statusOptions}
          onChange={(status) => handleStatusChange(status as { value: string, label: string })}
          placeholder="Filtrar por status"
          styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
          // menuPortalTarget={document.body}
          classNamePrefix="react-select"
          className="react-select-container-as-filter"
        />
        <Select
          value={{ value: selectedEngagement.value, label: selectedEngagement.label === 'Todos' ? 'Filtrar por engajamento' : selectedEngagement.label }}
          options={engagementOptions}
          onChange={(engagement) => handleEngagementChange(engagement as { value: string, label: string })}
          placeholder="Filtrar por engajamento"
          styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
          // menuPortalTarget={document.body}
          classNamePrefix="react-select"
          className="react-select-container-as-filter"
        />
      </div>

      <div>
        <FilterRadioButtonsContainer>
          <FilterRadioButton
            onClick={() => handleKindChange('')}
            selected={selectedKind === ''}
          >
            Todos
          </FilterRadioButton>
          <FilterRadioButton
            onClick={() => handleKindChange('b2b')}
            selected={selectedKind === 'b2b'}
          >
            B2B
          </FilterRadioButton>
          <FilterRadioButton
            onClick={() => handleKindChange('b2c')}
            selected={selectedKind === 'b2c'}
          >
            B2C
          </FilterRadioButton>
        </FilterRadioButtonsContainer>

        <Group>
          <header>
            Idade criança:
            {' '}
            {selectedAgeRange[1] === 13 ? 'Todas' : (
              selectedAgeRange[1] === 1
                ? `${selectedAgeRange[1]} ano`
                : `${selectedAgeRange[1]} anos`
            )}
          </header>
          <RangeSlider
            min={0}
            max={13}
            value={selectedAgeRange}
            onThumbDragEnd={() => handleAgeSelection(selectedAgeRange)}
            onInput={(evt: number[]) => setSelectedAgeRange(evt)}
            className="single-thumb"
            thumbsDisabled={[true, false]}
            rangeSlideDisabled
          />
        </Group>
      </div>
    </Container>
  );
}
