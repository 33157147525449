import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 2px;
  border-radius: 8px;
  &:hover {
      cursor: pointer;
  }

  label {
    font-size: 0.8rem;
    &:hover {
      cursor: pointer;
    }
  }

  img {
    margin: 8px;
    width: 7.3%;
    padding: 0;
  }

  & + & {
    margin-top: 8px !important;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.background};
    transition: ease-in-out 0.4s;
    }
  &:not(:hover) {
    transition: ease-in-out 0.3s;
    background: ${({ theme }) => theme.colors.lighterBackground};
  }
`;

export const ActiveItem = styled.div`
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  margin: 0rem 0rem;
  cursor: pointer;
  border-radius: 0.375em;
  width: 100%;

    a {
      color: ${({ theme }) => theme.colors.primary.dark} !important;
      width: 100%;
      div {
        background: ${({ theme }) => theme.colors.background} !important;
      }
      img {
        /* filter: invert(80%) sepia(63%) saturate(364%) hue-rotate(357deg) brightness(104%) contrast(103%); */
        filter: ${({ theme }) => ((theme.colors.background === '#F6F5FC')
    ? 'invert(25%) sepia(56%) saturate(3343%) hue-rotate(182deg) brightness(97%) contrast(101%);'
    : 'invert(80%) sepia(63%) saturate(364%) hue-rotate(357deg) brightness(104%) contrast(103%);')};
      }
    }
`;

export const GroupFileInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 4px 0px;
  margin-right: 16px;

  div.title {
    margin: 4px 0px;
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    div {
      font-size: 16px;
    }
    img {
      &:hover {
        opacity: 0.6
      }
      cursor: pointer;
      filter: ${({ theme }) => theme.filters.primary};
      width: 21px;
      transition: all 0.2s ease-in-out;
    }
  }

  span {
    max-height: 66px;
    overflow: auto;
    margin-bottom: 2px;
  }

  label {
    font-size: 12px;
    display: block;
    margin-bottom: -8px;
  }
`;
